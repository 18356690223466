// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    apiBaseUrl: 'https://www.majorcommand.com',
    apiUrl: 'https://www.majorcommand.com/gateway',
    serverUrl: 'wss://www.majorcommand.com/gateway/games',
    mapsUrl: 'https://www.majorcommand.com/maps',
    mapsUrlLocal: '/assets/local/maps',
    websiteUrl: 'https://www.majorcommand.com',
    envName: 'prod',
    sentryDSN: 'https://f4cff141e7bd4ff6a935a62056d262cb@sentry.io/1432814'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
