import { Component, OnInit } from '@angular/core';
import { ScoreboardService } from '@gamelist/services/api/scoreboard.service';

@Component({
    selector: 'app-scoreboard',
    templateUrl: './scoreboard.component.html',
    styleUrls: ['./scoreboard.component.scss']
})
export class ScoreboardComponent implements OnInit {

    currentPointsLeaderboard$;
    consecutiveDefeatedOpponentsCurrent$;
    consecutiveDefeatedOpponentsAlltime$;
    mostDecoratedPlayers$;
    mostOpponentsDefeated$;
    newUsers$;
    totalActiveUsers$;
    selectedScoreboard = 'current_points_leaderboard';

    constructor(private scoreboardService: ScoreboardService) { }

    ngOnInit() {
        this.currentPointsLeaderboard$ = this.scoreboardService.getCurrentPointsLeaderboard();
        this.consecutiveDefeatedOpponentsCurrent$ = this.scoreboardService.getConsecutiveDefeatedOpponents('current');
        this.consecutiveDefeatedOpponentsAlltime$ = this.scoreboardService.getConsecutiveDefeatedOpponents('alltime');
        this.mostDecoratedPlayers$ = this.scoreboardService.getMostDecoratedPlayers();
        this.mostOpponentsDefeated$ = this.scoreboardService.getMostOpponentsDefeated();
        this.newUsers$ = this.scoreboardService.getNewUsers();
        this.totalActiveUsers$ = this.scoreboardService.getTotalActiveUsers();
    }

}
