import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { GameService } from '@gamelist/services/api/game.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})

/*interface DispatchData {
    title?: string;
    image_url?: string;
    click_url?: string;
}*/

export class DashboardComponent implements OnInit {

    selectedGameType = null;
    dogtagData = {};
    dogtagData$;
    dispatchData = {};
    dispatchData$;
    activeGames$;
    awaitingGames$;
    archivedGames$;
    selectedTab = 'active';

    constructor(
            private gameService: GameService,
            private router: Router
        ) {

        }

    ngOnInit() {
        this.activeGames$ = this.gameService.getActivePlayerGames();
        this.awaitingGames$ = this.gameService.getAwaitingPlayerGames();
        this.archivedGames$ = this.gameService.getArchivedPlayerGames();
        this.dispatchData$ = this.gameService.getDispatchData();
        this.dogtagData$ = this.gameService.getDogTagData();
        this.dispatchData$.subscribe(val => {
            // console.log(val);
            this.dispatchData = val;
        });
        this.dogtagData$.subscribe(val => {
            console.log(val);
            this.dogtagData = val;
        });
    }

    selectGameType(gameType: string) {
        this.selectedGameType = gameType;
    }

    startGame(type: string) {
        this.router.navigate(['/beta/new'], { queryParams: { type: type } });
    }
    
    joinGame(type) {
        
        // this.router.navigateByUrl("/team/33/user/11");
        
        this.router.navigate(['/beta/search'], { queryParams: { type: type, available_slots:1 } });
    }

    searchGame() {
        
        this.router.navigateByUrl("/beta/search?showJoinedGames=true");
    }

    selectTab(tab: string) {
        this.selectedTab = tab;
    }
}
